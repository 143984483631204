import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  setToken(token) {
    if (token) {
      sessionStorage.setItem('token', token);
    }
  }
  get getToken() {
    return sessionStorage.getItem('token');
  }
}
