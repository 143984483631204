import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BusinessIncidentBody, BusinessIncidentResponseMapper, IncidentRiskDepartment, RiskCategory } from '../model/businessIncident/business.save';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  private readonly api = environment.baseUrl + '/business-incident';

  constructor(private http: HttpClient) {
  }


  public saveBusinessIncident(body: Partial<BusinessIncidentBody>, isSave: boolean) {
    if (isSave) {
      return this.http.post<BusinessIncidentBody>(this.api, body);
    }
    return this.http.put<BusinessIncidentBody>(this.api, body);
  }

  getBusinessIncident(incidentNo: string) {
    return this.http.get<BusinessIncidentResponseMapper>(this.api + '/incident/' + incidentNo);
  }

  getRiskCategory() {
    return this.http.get<RiskCategory[]>(this.api + '/risk-category/');
  }
  getBusinessIncidentSearch(keyword: string, status: string, period: string) {
    if(status !== 'ALL STATUS') {
      return this.http.get<BusinessIncidentBody>(`${environment.baseUrl}/business-incident/search`,{params: {keyword, status, period}});
    } else {
      return this.http.get<BusinessIncidentBody>(`${environment.baseUrl}/business-incident/search`,{params: {keyword, period}});
    }
  }

  getBusinessIncidentPendingApprove(approver: string, serviceName: string, impact: string) {
    let params = new HttpParams();
    params = params.append('approver', approver);
    params = params.append('serviceName', serviceName);
    params = params.append('impact', impact);
    
    return this.http.get<BusinessIncidentBody>(`${environment.baseUrl}/business-incident/approver`,{params: params});
  }

  getBusinessIncidentByDept(department: string) {
    return this.http.get<BusinessIncidentBody>(`${environment.baseUrl}/business-incident/department`,{params: {department}});
  }

  getBusinessIncidentAll() {
    return this.http.get<BusinessIncidentBody>(`${environment.baseUrl}/business-incident`);
  }

  getIncidentRiskDepartment(incidentNo: string): Observable<IncidentRiskDepartment[]>  {
    return this.http.get<IncidentRiskDepartment[]>(`${environment.baseUrl}/incident-risk-department/incident/` + incidentNo);
  }

  escalateToMCOM(incidentNo: string) {
    return this.http.get<any>(`${environment.baseUrl}/business-incident/send-notification/` + incidentNo);
  }

  getExampleImpactFile() {
    return this.http.get<any>(`${environment.baseUrl}/business-incident/example-impact`,{ observe: "response", responseType: "blob" as "json" });
  }
}
