import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IncidentService } from '../../../../../../core/services/incident.service';
import { Subscription } from 'rxjs';
import { RiskCategory } from '../../../../../../core/model/businessIncident/business.save';

@Component({
  selector: 'app-form-risk-category',
  templateUrl: './form-risk-category.component.html',
  styleUrls: ['./form-risk-category.component.scss']
})
export class FormRiskCategoryComponent implements OnInit, OnDestroy {

  riskCategoryListBackup: RiskCategory[] = [];
  riskCategory: string[] = [];
  riskEvent: RiskCategory[] = [];
  sub: Subscription = new Subscription();
  sub2: Subscription = new Subscription();
  disabled = false;
  @Input() Touched: boolean;
  @Output() formRiskDetail: EventEmitter<any> = new EventEmitter<any>();
  formRisk = this.fb.group({
    id: null,
    riskCategory: [null, Validators.required],
    riskEvent: [null, Validators.required]
  });

  @Input()
  set Disable(disabled: boolean) {
    this.disabled = disabled;
  }

  riskEventModel: RiskCategory;
  riskCategoryModel: string;

  get riskCategoryControl() {
    return this.formRisk.get('riskCategory') as FormControl;
  }

  @Input('InputControl')
  set prepareInputControl(i: AbstractControl) {
    this.inputControl = i as FormControl;
    this.sub = i.valueChanges.subscribe((v: RiskCategory) => {
      if (v) {
        this.sub.unsubscribe();
        const { createBy, updateBy, createDate, updateDate, ...rest } = v;
        this.riskEventModel = rest;
        this.riskCategoryModel = rest.riskCategory;
        this.inputControl.patchValue(rest);
        this.onRiskCategoryChange(null);
      }
    });
  }

  get InputControl() {
    return this.inputControl;
  }

  inputControl: FormControl;

  constructor(
    private fb: FormBuilder,
    private incidentService: IncidentService
  ) {
  }

  ngOnInit() {
    this.incidentService.getRiskCategory()
      .toPromise()
      .then(riskCategory => {
        this.riskCategoryListBackup = riskCategory;
        this.riskCategory = Array.from(new Set(this.riskCategoryListBackup.map(r => r.riskCategory)));
      }).finally(() => {
      this.onRiskCategoryChange(null);
    });
    this.sub2 = this.formRisk.valueChanges.subscribe(v => {
      this.inputControl.patchValue(v);
    });

    if(this.InputControl.value) {
      let rest : RiskCategory = this.InputControl.value;
      this.riskEventModel = rest;
      this.riskCategoryModel = rest.riskCategory;
      this.inputControl.patchValue(rest);
      this.onRiskCategoryChange(null);
      this.formRisk.patchValue(rest);
    }
  }

  onRiskCategoryChange(event) {
    this.riskEvent = this.riskCategoryListBackup.filter(v => v.riskCategory === this.riskCategoryModel);
    if(this.riskEvent.length === 0) {
      this.formRisk.reset();
      this.formRiskDetail.emit(this.formRisk.getRawValue());
    }
  }

  onEventChange(event) {
    this.formRisk.patchValue(event);
    if(!event) {
      this.formRisk.reset();
      this.formRiskDetail.emit(this.formRisk.getRawValue());
    }
  }

  compareEvent(e1: RiskCategory, e2: RiskCategory): boolean {
    if (!e1 || !e2) {
      return false;
    }
    return e1.id === e2.id;
  }

  ngOnDestroy(): void {
    this.sub2.unsubscribe();
  }
}
