import {Component, OnInit} from "@angular/core";
import { EmployeesService } from "./core/services/employees.service";
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "acknowledge-web";
  faCoffee = faCoffee;

  ngOnInit(): void {
    console.log('init')
    const r = localStorage.getItem('redirect');
    console.warn('redirect to ', r)
    if (r) {
      localStorage.removeItem('redirect')
      location.href = r
    }
  }
}
