import { Component, OnInit } from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {MsGraphService} from './core/services/msal/ms-graph.service';
import {SessionService} from './core/services/session.service';

@Component({
  selector: 'app-msal',
  template: `
    <p>
      msal works!
    </p>
  `,
  styles: []
})
export class MsalComponent implements OnInit {

  constructor(
    private authService: MsalService,
    private graphService: MsGraphService,
    private sessionService: SessionService
  ) { }

  private readonly msal = 'set-msal';

  ngOnInit() {
    const setMsal = localStorage.getItem(this.msal)

    if (setMsal) {
      this.checkProfile();
    } else {
      this.login();
    }
  }

  private checkProfile() {
    this.graphService.me().subscribe({
      next: value => {
        console.log('profile ', value)
        this.getProfile()
      },
      error: err => {
        if (err.status === 401) {
          this.login()
        }
      }
    })
  }

  private login() {
    this.authService.loginPopup()
      .subscribe({
        next: (result) => {
          localStorage.setItem(this.msal, result.accessToken);
          this.getProfile()
        },
        error: (error) => console.log(error)
      });
  }

  private getProfile() {
    this.sessionService.getProfile().subscribe({
      next: value => {
        console.log('tom ==> ', value)
      },
      error: err => {
        console.log('tom ==> ', err)
      }
    })
  }
}
