import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../../core/services/events.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CoWorkingService } from '../../../pages/co-working/core/service/co-working.service';

@Component({
  selector: 'app-emergency-landing',
  templateUrl: './emergency-landing.component.html',
  styleUrls: ['./emergency-landing.component.scss']
})
export class EmergencyLandingComponent implements OnInit {
  nav;
  empId;
  code;
  isLoading = false;

  constructor(
    private eventService: EventsService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private coworkingService: CoWorkingService
  ) {
  }

  ngOnInit() {
    const { nav, empId, code, hotkey } = this.activateRoute.snapshot.queryParams;
    this.nav = nav;
    this.empId = empId;
    this.code = code;
    if (code || hotkey) {
      this.coworkingService.setToken(code || hotkey);
    }
    if (this.router.url.includes('/emergencyCheckIn')) {
      this.acknowledgeSubmitRedirect();
    } else if (this.router.url.includes('/emergencyDashboard')) {
      this.redirectDashboard();
    }

  }

  private redirectDashboard() {
    this.eventService.getNowActivateEventType().toPromise()
      .then((e) => {
        if (e.eventType === 'bcp') {
          console.log('redirect to bcp active');
          this.router.navigate(['/acknowledge/bcp/dashboard'], { queryParams: { nav: this.nav, empId: this.empId, code: this.code } });
        } else {
          console.log('redirect to fire active');
          this.router.navigate(['/acknowledge/fire/dashboard'], { queryParams: { nav: this.nav, empId: this.empId, code: this.code } });
        }
      })
      .catch(() => { });
  }

  private acknowledgeSubmitRedirect() {
    this.isLoading = true;
    this.eventService.getNowActivateEventType().toPromise()
      .then((e) => {
        if (e.eventType === 'bcp') {
          console.log('redirect to bcp active');
          this.router.navigate(['/acknowledge/bcp/submit'], { queryParams: { nav: this.nav, empId: this.empId } });
        } else {
          console.log('redirect to fire active');
          this.router.navigate(['/acknowledge/fire/submit'], { queryParams: { nav: this.nav, empId: this.empId } });
        }
      })
      .finally(() => {
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
