import { Injectable } from '@angular/core';
import {
  CanActivate,
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingService implements CanActivate {
  isBcp = new BehaviorSubject(false);
  // isAcknowledge = new BehaviorSubject(false);
  // isOverall = new BehaviorSubject(false);
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.isBcp.next(route.data.isBcp);
    // this.isAcknowledge.next(route.data.isAcknowledge);
    // this.isOverall.next(route.data.isOverall);

    return true;
  }

  constructor() {}
}
