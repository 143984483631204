import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmployeeInterceptor implements HttpInterceptor {
  readonly UNAUTHORISED = 'Unauthorized';
  readonly INVALID_SESSION = 'invalid session';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const h = {
      'client-origin': window.location.href,
    };


    const token = localStorage.getItem('set-msal');
    if (token) {
      h['Authorization'] = `Bearer ${token}`
    }

    const headers = new HttpHeaders(h);

    const requestClone = req.clone({
      withCredentials: !req.url.startsWith('https://graph.microsoft.com'),
      headers
    });

    let endpoint = '/oauth2';
    if (environment.local) {
      endpoint = endpoint + 'local'
    }

    return next.handle(requestClone).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: HttpErrorResponse) => {
          console.log(error);
          if (error.error && error.error.message && this.UNAUTHORISED.toLowerCase() === error.error.message.toLowerCase()) {
            localStorage.setItem('redirect', location.href);
            location.href = environment.baseUrl + endpoint;
          }

          if (error.error && error.error.message && this.INVALID_SESSION.toLowerCase() === error.error.message.toLowerCase()) {
            localStorage.setItem('redirect', location.href);
            location.href = environment.baseUrl + endpoint;
          }
        }
      )
    );
  }
}
export const EmployeeInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EmployeeInterceptor,
  multi: true
};
