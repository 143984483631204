import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Employee, DeptRelation } from './services.interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { TokenService } from 'src/app/pages/bcp-mobile/core/services/token.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  profile$: Observable<Employee>;
  role$: Observable<Employee>;
  pure = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {
  }

  getProfile(): Observable<Employee> {
    if (
      this.tokenService.getToken === 'null' ||
      this.tokenService.getToken === 'undefined'
    ) {
      sessionStorage.removeItem('token')
    }

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });

    if (this.tokenService.getToken) {
      return this.http.get<Employee>(`${environment.baseUrl}/session/profile`, { headers: httpHeaders });
    } else {
      if (!this.profile$) {
        this.profile$ = this.http.get<Employee>(`${environment.baseUrl}/session/profile`).pipe(shareReplay(1));
      }
      return this.profile$;
    }
  }

  //defualt dept
  getDefaultDept(): Observable<DeptRelation> {
    return this.getProfile().pipe(map((x) => x.deptRelation));
  }

  getSession() {
    //console.log(localStorage.getItem('account'));
  }

  getRole() {
    if (!this.role$) {
      this.role$ = this.http.get<Employee>(`${environment.baseUrl}/check-role`).pipe(shareReplay(1));
    }
    return this.role$;
  }
}
