import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EmployeeInterceptorProvider } from './core/interceptors/employee.interceptor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SETDNATokenInterceptorProvider } from './core/interceptors/token.interceptor';
import { EmergencyLandingComponent } from './components/emergency-landing-page/emergency-landing/emergency-landing.component';
import { ChartModule } from 'angular2-chartjs';
import {
  FormRiskCategoryComponent
} from './pages/crisis-communication/case-flagging/business-incident/business-incident-form/form-risk-category/form-risk-category.component';
import {
  FormImpactComponent
} from './pages/crisis-communication/case-flagging/business-incident/business-incident-form/form-impact/form-impact.component';
import { MsalComponent } from './msal.component';
import { MsalModule } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: '53592b32-6f20-45a9-ae30-4d1d6f4bd84f', // Application (client) ID from the app registration
    authority: 'https://login.microsoftonline.com/7cf70668-de90-4e0a-bdc1-42dd27fc4aee', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
    redirectUri: `${environment.baseWeb}/msal`// This is your redirect URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
  }
});

@NgModule({
  declarations: [AppComponent,
    EmergencyLandingComponent,
    FormRiskCategoryComponent,
    FormImpactComponent,
    MsalComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    BsDatepickerModule.forRoot(),
    ChartModule,
    MsalModule.forRoot(publicClientApplication, null, null),
    ToastrModule.forRoot(),
  ],
  providers: [EmployeeInterceptorProvider, SETDNATokenInterceptorProvider],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
