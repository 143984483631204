import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-impact',
  templateUrl: './form-impact.component.html',
  styleUrls: ['./form-impact.component.scss']
})
export class FormImpactComponent implements OnInit, OnDestroy {
  formImpact = this.fb.group({
    impact1: false,
    impact2: false
  });
  sub1: Subscription = new Subscription();
  sub2: Subscription = new Subscription();
  @Input()
  InputControl: AbstractControl;


  @Input()
  set Disable(disabled: boolean) {
    if (disabled) {
      this.formImpact.disable();
    } else {
      this.formImpact.enable();
    }
  }

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {

    this.sub1 = this.InputControl.valueChanges.subscribe(v => {
      if (v) {
        this.patchControl(v);
      }
    });
    this.sub2 = this.formImpact.valueChanges.subscribe(({ impact1, impact2 }) => {
      this.sub1.unsubscribe();
      if (impact1 && impact2) {
        this.InputControl.patchValue('BOTH');
      } else if (impact1) {
        this.InputControl.patchValue('INTERNAL');
      } else if (impact2) {
        this.InputControl.patchValue('EXTERNAL');
      } else {
        this.InputControl.patchValue('NONE');
      }
    });
  }

  ngOnDestroy(): void {
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();

  }

  private patchControl(v: string) {
    if (v === 'NONE' || !v) {
      this.formImpact.patchValue({
        impact1: null,
        impact2: null
      });
    } else if (v === 'BOTH') {
      this.formImpact.patchValue({
        impact1: true,
        impact2: true
      });
    } else if (v === 'EXTERNAL') {
      this.formImpact.patchValue({
        impact1: false,
        impact2: true
      });
    } else if (v === 'INTERNAL') {
      this.formImpact.patchValue({
        impact1: true,
        impact2: false
      });
    }


  }


}
