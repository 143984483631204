import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoWorkingService } from '../../pages/co-working/core/service/co-working.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  exceptPath = [
    'co-working',
    'work-at-site-plan',
    'bcp-team',
    'survey',
    'covid-emp',
    'escalated-incident',
    'jira',
    'session',
    'vaccinated',
    'what-new'
  ];
  exceptURL6 = 'escalated-incident';
  exceptURL7 = 'jira';

  constructor(
    private coWorkingService: CoWorkingService,
    private router: Router
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this.coWorkingService.getToken;
    if (token != null) {
      if (this.exceptPath.some(path => request.url.includes(path)) ||
        this.escalatedIncident(request) ||
        this.jiraService(request) ||
        this.isAcknowledge
      ) {

        let authType = 'dna';
        if (token.length === 20) {
          authType = 'hot-key';
        }
        authReq = request.clone({
          headers: request.headers.set('Authorization', `${authType} ` + token)
        });
      }
    }
    console.log('interceptor work!');
    return next.handle(authReq);
  }

  private escalatedIncident(request: HttpRequest<unknown>) {
    const found = request.url.includes(this.exceptURL6);
    return found && this.router.url.startsWith('/mobile');
  }

  private jiraService(request: HttpRequest<unknown>) {
    const found = request.url.includes(this.exceptURL7);
    return found && this.router.url.startsWith('/mobile');
  }

  private get isAcknowledge() {
    return (this.router.url.startsWith('/acknowledge') && !this.router.url.includes('fire/dashboard')) ||
      this.router.url.startsWith('/emergency');
  }
}

export const SETDNATokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};
