import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CoWorkingList } from '../model/coWorkingSpaceListModel';
import { CoWorkingZoneListMapper } from '../model/coWorkingSpeceZoneList';

@Injectable({
  providedIn: 'root'
})
export class CoWorkingService {
  private endpoint = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getZoneList(date, building = 'cmc', floor = ''): Observable<CoWorkingList[]> {
    return this.http.get<CoWorkingList[]>(`${this.endpoint}/co-working/list`, {
      params: { date, building, floor }
    });
  }

  getMyList(empId = '', building = 'cmc'): Observable<CoWorkingZoneListMapper[]> {

    return this.http.get<CoWorkingZoneListMapper[]>(
      `${this.endpoint}/co-working/my-list`, { params: { empid: empId, building } }
    );
  }

  reserve(reserveBody: ReserveBody, date, empid = ''): Observable<any> {
    return this.http.put(`${this.endpoint}/co-working/reserve`, reserveBody, {
      params: { date, empid: empid }
    });
  }

  cacel(id) {
    return this.http.delete(`${this.endpoint}/co-working/reserve/${id}`);
  }

  // setEmpId(empId) {
  //   localStorage.setItem('empId', empId);
  // }
  // get getEmpId() {
  //   return localStorage.getItem('empId');
  // }
  setToken(token) {
    if (token) {
      console.log('set token');
      sessionStorage.setItem('token', token);
    }
  }

  get getToken() {
    return sessionStorage.getItem('token');
  }
}

export interface ReserveBody {
  zoneId: number;
  // empId: string;
}
