import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppRoutingService} from './app-routing.service';
import {ConfigRoute} from './configs/routeConfig';
import {
  EmergencyLandingComponent
} from './components/emergency-landing-page/emergency-landing/emergency-landing.component';
import {MsalComponent} from './msal.component';
import {BrowserUtils} from '@azure/msal-browser';

const routes: Routes = [
  // {
  //   path: 'crisis-comm',
  //   loadChildren: () => import('./pages/crisis-communication/crisis-communication.module').then(m => m.CrisisCommunicationModule)
  // },
  {
    path: ConfigRoute.acknowledge,
    loadChildren: () =>
      import('./pages/acknowledge/app-acknowledge.module').then(
        (m) => m.AppAcknowledgeModule
      ),
    data: {isBcp: true},
    canActivate: [AppRoutingService]
  },
  {
    path: ConfigRoute.bcpPath,
    loadChildren: () =>
      import('./pages/bcp/app-bcp.module').then((m) => m.AppBcpModule),
    data: {isBcp: true},
    canActivate: [AppRoutingService]
  },
  {
    path: ConfigRoute.covid,
    loadChildren: () =>
      import('./pages/covid/covid.module').then((m) => m.CovidModule),
    data: {isBcp: false},
    canActivate: [AppRoutingService]
  },
  {
    path: 'co-working',
    loadChildren: () =>
      import('./pages/co-working/co-working.module').then(
        (m) => m.CoWorkingModule
      )
  },
  {
    path: 'mobile',
    loadChildren: () =>
      import('./pages/bcp-mobile/bcp-mobile.module').then(
        (m) => m.BcpMobileModule
      )
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then(
      m => m.SurveyModule
    )
  },
  {
    path: 'geo',
    loadChildren: () => import('./pages/geo/geo.module').then(
      m => m.GeoModule
    )
  },
  {path: 'bcpMobile', loadChildren: () => import('./pages/bcp-mobile/bcp-mobile.module').then(m => m.BcpMobileModule)},
  {path: 'emergencyCheckIn', component: EmergencyLandingComponent},  // emergency lading page
  {path: 'emergencyDashboard', component: EmergencyLandingComponent}, // emergency lading page
  {
    path: 'crisis-comm',
    loadChildren: () => import('./pages/crisis-communication/crisis-communication.module').then(m => m.CrisisCommunicationModule)
  },
  {
    path: 'msal', component: MsalComponent
  },
  { path: 'intern', loadChildren: () => import('./pages/intern/intern.module').then(m => m.InternModule) },
  {
    path: '**',
    redirectTo: 'bcp'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
