import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GraphMe} from '../../model/msal/graph-me';

@Injectable({
  providedIn: 'root'
})
export class MsGraphService {

  constructor(private http: HttpClient) { }

  me(): Observable<GraphMe> {
    return this.http.get<GraphMe>(`https://graph.microsoft.com/v1.0/me`)
  }
}
