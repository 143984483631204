export const ConfigRoute = {
  root: '',
  acknowledge: 'acknowledge',
  acknowledgeSubmit: 'submit',
  overall: 'overall',
  bcpPath: 'bcp',
  bcpAcknowlege: 'bcp-ack',
  bcpCallTreeDashboard: 'bcp-call-tree-dashboard/:id',
  bcpDepartments: 'departments',
  bcpEmployeesDept: 'employees/employees/:dept',
  bcpEmployees: 'staff',
  bcpActivate: 'activate',
  bcpCallTree: 'bcp-call-tree',
  covid: 'covid',
};
