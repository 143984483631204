import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Event } from './services.interfaces';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private http: HttpClient) {
  }

  getActivateEvent(): Observable<Event> {
    return this.http.get<Event>(`${environment.baseUrl}/event/bcp/activated`);
  }

  public getFireActivateEvent(): Observable<Event> {
    return this.http.get<Event>(
      `${environment.baseUrl}/public/event/fire-activate`
    );
  }

  public getBCPActivateEvent(): Observable<Event> {
    return this.http.get<Event>(
      `${environment.baseUrl}/public/event/bcp-activate`
    );
  }


  public getNowActivateEventType(): Observable<NowActivateEventType> {
    return this.http.get<NowActivateEventType>(
      `${environment.baseUrl}/public/event/type`
    );
  }

  bcpEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.baseUrl}/events/bcp`);
  }

  getAllEvents(): Observable<Event[]> {
    return this.http.get<Event[]>(`${environment.baseUrl}/events`);
  }

  getEventById(id) {
    return this.http.get<Event>(`${environment.baseUrl}/event/${id}`);
  }

  // new
  activateBcpEvent(body) {
    const url = `${environment.baseUrl}/event/bcp`;
    return this.http.post<Event>(url, body);
    // console.log('koy koy =====> new event', body)
  }

  // update
  deactiveBcpEvent(eventID, body) {
    const url = `${environment.baseUrl}/event/bcp/status/${eventID}`;
    return this.http.put<Event>(url, body);
    // console.log('koy koy =====> update event', body)
  }

}

export interface NowActivateEventType {
  eventType: string;
}
